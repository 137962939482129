<template>
  <div>
    <div class="layout-main-body">
      <el-card shadow="never">
        <div>
          <el-result icon="error" title="提示" sub-title="帐户VIP年卡数不足，请联系管理员充值" v-if="source === '1'">
          </el-result>
          <el-result icon="success" title="开通成功" v-if="source === '2'">
            <template slot="extra">
              <el-button type="primary" @click="handleGotoBack">继续开通</el-button>
            </template>
          </el-result>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>

export default {
  name: 'OrderVipCreateResult',
  data () {
    return {
      source: ''
    }
  },
  methods: {
    handleGotoBack () {
      this.$router.back()
    },
    handleGotoOrderPage () {
      this.$router.replace('/account/order')
    },
    handleGotoRechargeVipCardPage () {
      this.$router.replace('/account/recharge/vip/card')
    }
  },
  created: function () {
    this.source = this.$route.query.source
  }
}
</script>

<style scoped lang="less">

</style>
